export function isInViewport(el, minViewPercent = 80) {
  const { yHiddenPercent, xHiddenPercent } = inViewportPercent(el);
  return (100 - (xHiddenPercent + yHiddenPercent)) >= minViewPercent;
}

export function inViewportPercent(el) {
  const rect = el.getBoundingClientRect();
  const { height } = rect;
  const { width } = rect;
  const pageHeight = (window.innerHeight || document.documentElement.clientHeight);
  const pageWidth = (window.innerWidth || document.documentElement.clientWidth);
  let yHiddenPercent = 0;
  let yShownPercent = 0;
  let xHiddenPercent = 0;
  let xShownPercent = 0;
  if (rect.top < 0) {
    yHiddenPercent = 100 - ((height - Math.abs(rect.top)) / height * 100);
  } else if (rect.top > pageHeight) {
    if (rect.top >= pageHeight + height) {
      yHiddenPercent = 100;
    } else {
      yHiddenPercent = 100 - ((height - (rect.top - pageHeight)) / height * 100);
    }
  }

  if (rect.left < 0) {
    xHiddenPercent = 100 - ((width - Math.abs(rect.left)) / width * 100);
  } else if (rect.left > pageWidth) {
    if (rect.left >= pageWidth + width) {
      xHiddenPercent = 100;
    } else {
      xHiddenPercent = 100 - (width - (rect.left - pageWidth)) / width * 100;
    }
  }
  yShownPercent = 100 - yHiddenPercent;
  xShownPercent = 100 - xHiddenPercent;
  return { yShownPercent, xHiddenPercent, xShownPercent, yHiddenPercent };
}
import { mapActions, mapState } from 'vuex';
import { inViewportPercent, isInViewport } from '@/shared/helpers/inViewport';
import device from '@/service/device-service';

export default {

  data() {
    return {
      scrollShownHistory: null,
    };
  },
  computed: {
    ...mapState('floatPlayer', {
      floatItem: 'floatItem',
      floatPlaybackState: 'playbackState',
      floatOptions: 'options',
    }),
    ...mapState('popupPlayer', {
      popupItem: 'item',
    }),
  },

  watch: {
    floatOptions(floatOptions) {
      if (floatOptions?.closed || floatOptions?.returned) {
        const activeItem = this.getActiveItem();
        const shopPlayerRef = this.getShopPlayerRef();
        const floatItemId = this.floatOptions.itemId || this.floatItem?.id;

        // apply state from float player to the main player
        if (floatOptions.openerPage === this.$route.fullPath) {
          if (floatItemId === activeItem?.id && shopPlayerRef) {
            const scrollShown = inViewportPercent(shopPlayerRef.$el);
            this.applyFloatPlayerStateToMain(null, floatOptions?.closed && scrollShown.yShownPercent < 99);
            this.hideFloatPlayer();
          }
        }
      }
    },
  },
  created() {
    document.addEventListener('scroll', this.onDocumentScroll);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onDocumentScroll);
  },
  methods: {
    ...mapActions({
      setFloatItem: 'floatPlayer/setFloatItem',
      setFloatPlaybackState: 'floatPlayer/setPlaybackState',
      setFloatOptions: 'floatPlayer/setFloatOptions',
    }),
    // onChangedUserState() {
    //   console.log('onChangedUserState float-mixin');
    //   if (this.floatItem && !this.canPlay(this.floatItem)) {
    //     this.hideFloatPlayer();
    //   }
    // },
    onDocumentScroll() {

      if (this.isShopPlayerEnabled === false) {
        return;
      }

      if (device.isAnyMobile() && document.activeElement?.tagName === 'INPUT') {
        // in case user focus some input element
        return;
      }

      if (this.shopPlayerActiveItem?.is_private && !this.shopPlayerActiveItem?.playback_url) {
        return;
      }

      if (!this.canPlay(this.shopPlayerActiveItem)) {
        return;
      }

      const activeItem = this.getActiveItem();
      const shopPlayerRef = this.getShopPlayerRef();

      if (!shopPlayerRef) {
        return;
      }

      if (!this.isScrollFloatPlayerEnable) {
        return;
      }

      if (this.popupItem) {
        return;
      }

      if (this.floatOptions?.ignoreScroll) {
        return;
      }

      const floatItemId = this.floatOptions.itemId || this.floatItem?.id;
      const scrollShown = inViewportPercent(shopPlayerRef.$el);
      if (this.shopPlayerPlaybackState?.paused && !floatItemId) {
        return;
      }

      if ((this.floatItem && activeItem?.id === floatItemId && scrollShown.yShownPercent >= 99)) { // close
        const playbackState = { ...this.floatPlaybackState };

        this.applyFloatPlayerStateToMain(playbackState);
        this.hideFloatPlayer();
        this.$nextTick(() => {
          if (!playbackState.paused) {
            shopPlayerRef.play(activeItem, true);
          }
        });
      } else if (
        activeItem
        && !this.floatItem
        && !(this.floatOptions.closed || this.floatOptions.returned)
        && scrollShown.yShownPercent >= 10 && scrollShown.yShownPercent < 50
        && this.scrollShownHistory?.yShownPercent > scrollShown.yShownPercent // when scroll down
      ) { // show float item with current active item
        const playbackState = { ...shopPlayerRef.getState(activeItem, 'playback') };
        console.log('[scroll][enter]', playbackState, this.$route.fullPath, location.pathname);
        this.setFloatPlaybackState(playbackState);
        this.setFloatItem({ ...activeItem });
        // bug with vue router
        this.setFloatOptions({
          page: location.pathname,
          openerPage: location.pathname,
          item: { ...activeItem },
          itemId: activeItem.id,
          tab: this.activeTab,
        });
        this.$nextTick(() => {
          shopPlayerRef.pause(activeItem, true);
        });
      }
      this.scrollShownHistory = scrollShown;
    },
    applyFloatStateAfterInitShopPlayer(itemId) {
      const floatPlaybackState = { ...this.floatPlaybackState };
      console.log('[FloatPlayer][applyFloatState][setInterval], wait main player loaded to apply playback state', {
        shopPlayerActiveItem: { ...this.shopPlayerActiveItem },
        floatPlaybackState: floatPlaybackState,
        shopPlayerPlaybackState: { ...this.shopPlayerPlaybackState },
      });
      const intervalID = setInterval(() => {
        if (!this.shopPlayerActiveItem) {
          return;
        }
        const shopPlayerRef = this.getShopPlayerRef();
        if (this.shopPlayerActiveItem.id === itemId && !this.floatItem) {
          if (this.shopPlayerPlaybackState.loadedMetadata) {
            if (!floatPlaybackState.paused) {
              shopPlayerRef.play(null, false);
            }
            console.log('[FloatPlayer][applyFloatState][clearInterval] applied!', {
              shopPlayerActiveItem: { ...this.shopPlayerActiveItem },
              floatPlaybackState: floatPlaybackState,
              shopPlayerPlaybackState: { ...this.shopPlayerPlaybackState },
            });
            this.applyFloatPlayerStateToMain(floatPlaybackState);
            clearInterval(intervalID);
          }
        } else {
          console.log('[FloatPlayer][applyFloatState][clearInterval] not applied!', {
            shopPlayerActiveItem: { ...this.shopPlayerActiveItem },
            floatPlaybackState: floatPlaybackState,
            shopPlayerPlaybackState: { ...this.shopPlayerPlaybackState },
          });
          clearInterval(intervalID);
        }
      }, 200);
    },
    openFloatPlayer({ item, playbackState }) {
      this.$refs.shopPlayer.pause(null, true);
      setTimeout(() => {
        this.setFloatPlaybackState({ ...playbackState });
        this.setFloatItem({ ...item });
        this.setFloatOptions({
          page: location.pathname,
          openerPage: location.pathname,
          ignoreScroll: true,
          item: { ...item },
          itemId: item.id,
          tab: this.activeTab,
        });
      });
    },
    applyFloatPlayerStateToMain(floatPlaybackState, ignorePlayPause = false) {
      const activeItem = this.getActiveItem();
      const shopPlayerRef = this.getShopPlayerRef();
      const playbackState = floatPlaybackState || { ...this.floatPlaybackState };
      if (playbackState.loadedMetadata && playbackState.firstPlay) { // float player fully loaded and have duration
        if (playbackState.muted) {
          shopPlayerRef.mute();
        } else {
          shopPlayerRef.unmute();
        }
        if (!ignorePlayPause) {
          if (playbackState.paused) {
            // this.shopPlayerAutoplay = false; // it needs to prevent autoplay wnen returns from another page
            shopPlayerRef.pause(null, false);
            this.$nextTick(() => {
              shopPlayerRef.pause(null, false);
            }, 200);
          } else {
            shopPlayerRef.play(null, false);
          }
        }
        if (playbackState.position) {
          shopPlayerRef.seekTo(activeItem, playbackState.position);
        }
      }
    },
    hideFloatPlayer() {
      this.setFloatItem(null);
      this.setFloatOptions(null);
      this.setFloatPlaybackState(null);
    },
  },
};